import {CmsObject, Image} from '../../../models/cms-components';

export class ContactModel {
  constructor(
    public titel: string,
    public telefoon: string,
    public email: string,
    public contacten: Array<Contact>) {
  }

  static fromDto(dto: ContactModelDto): ContactModel {
    return new ContactModel(
      dto.contactInfo.titel,
      dto.contactInfo.telefoon,
      dto.contactInfo.email,
      dto.contactInfo.contacten.map(contactDto => Contact.fromDto(contactDto)));
  }
}

export class Contact {
  constructor(
    public naam: string,
    public rol: string,
    public telefoon: string,
    public email: string,
    public afbeelding: Image) {
  }
  static fromDto(dto: ContactDto): Contact {
    return new Contact(dto.naam, dto.rol, dto.telefoon, dto.email, dto.afbeelding?.data?.attributes);
  }
}

export interface ContactModelDto {
  contactInfo: ContactInfoDto;
}

interface ContactInfoDto {
  titel: string;
  telefoon: string;
  email: string;
  contacten: ContactDto[];
}

interface ContactDto {
  naam: string;
  rol: string;
  telefoon: string;
  email: string;
  afbeelding: CmsObject<Image>;
}
