<img
  [title]="logo.logoTitle"
  [alt]="logo.logoAlt"
  src="./assets/images/{{logo.logoImg}}"/>
<button mat-icon-button aria-label="menu" (click)="toggle()">
  <mat-icon>menu</mat-icon>
</button>
<mat-sidenav-container>
  <mat-sidenav #sidenav position="end" [fixedInViewport]="true">
    <div *wvHasGeslotenDeel class="login-logout">

      <ng-container *ngIf="isUserAuthenticated() else notLoggedIn">
        <ng-container *ngIf="(user$ | async) as user else notLoggedIn">
          <button class="welcome"><span class="wv-title">Welkom, {{user.voornaam}}</span></button>
          <button (click)="logout()" class="login-btn"><span class="wv-title">Uitloggen</span></button>
        </ng-container>
      </ng-container>

      <ng-template #notLoggedIn>
        <button class="login-btn" (click)="goToLogin()"><span class="wv-title">Login</span></button>
      </ng-template>

    </div>
    <mat-nav-list>
      <ng-container *wvHasGeslotenDeel>
        <a *ngIf="(user$ | async)"
           (click)="goToGeslotenPlatform()">
          <h3>Dashboard</h3>
        </a>
      </ng-container>
      <a
        *ngFor="let menuItem of menuItems"
        mat-list-item
        [routerLink]="menuItem.websiteRoute"
        (click)="close()">
        <h3>{{ menuItem.label }}</h3>
      </a>

    </mat-nav-list>

  </mat-sidenav>
</mat-sidenav-container>
