import {Component} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

@Component({
  templateUrl: './registration-information-dialog.component.html',
  styleUrls: ['./registration-information-dialog.component.scss']
})
export class RegistrationInformationDialogComponent {

  constructor(private dialogRef: MatDialogRef<RegistrationInformationDialogComponent>) {
  }

  close() {
    this.dialogRef.close(true);
  }
}
