import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CookieDialogService} from './cookie/services/cookie-dialog.service';

@Component({
  selector: 'wv-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private router: Router,
              private cookieDialogService: CookieDialogService) {
  }

  ngOnInit(): void {
    this.cookieDialogService.initializeCookies();
  }

  notZinmeterRoute(): boolean {
    return !this.router.isActive('zinmeter', false);
  }

}
