import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {User, JwtPayload, UserDto} from '../models/user';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) {
  }

  getMe$(jwtPayload: JwtPayload): Observable<User> {
    const user = User.fromJwt(jwtPayload as JwtPayload);
    if (user.isVliegwiel()) {
      return this.http.get<UserDto>(`${environment.apiUrl}/gebruikers/me`)
        .pipe(map(dto => User.fromJwt(jwtPayload, dto)));
    } else if (user.isAdmin()) {
      return this.http.get<UserDto>(`${environment.apiUrl}/admins/me`)
        .pipe(map(dto => User.fromJwt(jwtPayload, dto)));
    } else {
      return of(user);
    }
  }
}
