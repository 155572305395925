<div class="background-stripe-container">
  <div class="content">
    <ng-content></ng-content>
  </div>
  <div class="stripe"
       [ngClass]="color"
       [style.transform]="'skewY(-5deg) translateY(' + stripeOffsetY + 'rem)'"
       [style.height]="'calc(100% + ' + extraHeight + 'rem)'"
       [style.z-index]="zIndex">
    <span></span>
  </div>
</div>
