import {CircularImageLinkModel, CircularImageLinkModelDto} from '../../shared/components/circular-image-link/circular-image-link-model';

export class VoettekstModel {
  constructor(
    public andereProjectenTitel: string,
    public andereProjectenBeschrijving: string,
    public partnersTitel: string,
    public partners: Array<CircularImageLinkModel>) {
  }

  static fromDto(dto: VoettekstModelDto): VoettekstModel {
    return new VoettekstModel(
      dto.voettekstInfo.andereProjectenTitel,
      dto.voettekstInfo.andereProjectenBeschrijving,
      dto.voettekstInfo.partnersTitel,
      dto.voettekstInfo.partners.map(partnerDto => CircularImageLinkModel.fromDto(partnerDto)));
  }

}

interface VoettekstInfo {
  andereProjectenTitel: string;
  andereProjectenBeschrijving: string;
  partnersTitel: string;
  partners: CircularImageLinkModelDto[];
}

export interface VoettekstModelDto {
  voettekstInfo: VoettekstInfo;
}
