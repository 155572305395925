import {Component, EventEmitter, Input, Optional, Output, Self} from '@angular/core';
import {ControlValueAccessor, NgControl} from '@angular/forms';

@Component({
  selector: 'wv-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements ControlValueAccessor {

  @Input() label: string;
  @Input() disabled: boolean;
  @Input() link: string;
  @Output() checkboxChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  value: boolean;

  constructor(@Self()
              @Optional()
              private ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  onTouched: () => void = () => {};
  onChanged: (value: any) => void = () => {};

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange(event: any) {
    this.onChanged(event.target.checked);
    this.checkboxChanged.emit(event.target.checked);
  }
}
