import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'wv-zin-actie',
  templateUrl: './zin-actie.component.html',
  styleUrls: ['./zin-actie.component.scss']
})
export class ZinActieComponent implements OnInit {

  @Input() beschrijving: string;
  @Input() knopTekst: string;
  @Input() disabled = false;
  @Output() buttonClick: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onButtonClick(): void {
    this.buttonClick.emit();
  }

}
