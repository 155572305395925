<ng-container *ngIf="data$ | async as data">
  <wv-nieuwsbrief-form
    *ngIf="!inschrijvingVerstuurd && !foutOpgetreden"
    [data]="data"
    (inschrijving)="onInschrijving($event)">
  </wv-nieuwsbrief-form>

  <ng-container *ngIf="inschrijvingVerstuurd">
    <h3>{{data.titel}}</h3>
    <p>
      Bedankt om je in te schrijven voor onze nieuwsbrief. <br>
      Je zal deze ontvangen op {{inschrijving.email}}
    </p>
  </ng-container>

  <ng-container *ngIf="foutOpgetreden">
    <h3>{{data.titel}}</h3>
    <p class="wv-error">
      Oei, er is iets fout gelopen tijdens het inschrijven. <br>
      Probeer het later opnieuw.
    </p>
  </ng-container>
</ng-container>
