import {Component} from '@angular/core';

@Component({
  selector: 'wv-double-border-header-component',
  templateUrl: './double-border-header.component.html',
  styleUrls: ['./double-border-header.component.scss']
})
export class DoubleBorderHeaderComponent{

  constructor() { }

}
