import {Injectable} from '@angular/core';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {NotificationComponent, NotificationType} from './notification.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private snackBar: MatSnackBar) {
  }

  showNotificationBar(message: string, notificationType: NotificationType, duration: number = 3) {
    this.snackBar.openFromComponent(NotificationComponent, {
      duration: duration * 1000,
      data: {
        message,
        notificationType
      },
      panelClass: 'custom-position-notification-bar'
    });
  }
}
