import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'wv-double-border-button',
  templateUrl: './double-border-button.component.html',
  styleUrls: ['./double-border-button.component.scss']
})
export class DoubleBorderButtonComponent implements OnInit {

  @Input() large = false;
  @Input() arrow = true;
  @Input() blueButton = false;
  @Output() buttonClick: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClick() {
    this.buttonClick.emit();
  }
}
