export class PrivacyEnCookieVerklaringUrl {
  constructor(
    public cookieVerklaring: string,
    public privacyVerklaring: string
    ) {
  }

  static fromDto(dto: PrivacyVerklaringUrlsDto): PrivacyEnCookieVerklaringUrl {
    return new PrivacyEnCookieVerklaringUrl(
      dto.cookieVerklaring,
      dto.privacyVerklaring
    );
  }
}

export interface PrivacyVerklaringUrlsDto {
  cookieVerklaring: string;
  privacyVerklaring: string;
}
