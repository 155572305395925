import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'wv-markdown',
  templateUrl: './markdown.component.html',
  styleUrls: ['./markdown.component.scss']
})
export class MarkdownComponent implements OnInit {

  @Input() whiteText = false;
  @Input() centerText = false;

  constructor() { }

  ngOnInit(): void {
  }

}
