import {WarmeScholenConfig} from '../app/website-configuration/warme-scholen-config';
import {Tenant} from '../app/website-configuration/tenant';

export const environment = {
  production: true,
  preview: false,
  tenant: Tenant.WARME_SCHOLEN,
  apiUrl: 'https://api.warmescholen.net',
  cmsApiUrl: 'https://cms.warmvlaanderen.net/api',
  cmsApiVoorvoegsel: 'ws',
  applicationTitle: 'Warme Scholen',
  websiteConfiguratie: WarmeScholenConfig,
  matomoSiteId: '7',
  forumUrl: 'https://forum.warmescholen.net',
  auth: {
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_F3rLa5P5W',
    userPoolWebClientId: '3r75lstfs0ho47cd3dl935e607',
    cognitoDomain: 'https://warm-vlaanderen-prd-ws.auth.eu-central-1.amazoncognito.com'
  },
};
