import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'wv-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {

  @Input() disabled = false;
  @Input() isCancelButton = false;
  @Input() iconName;
  @Input() onlyIcon = false;
  @Output() buttonClick: EventEmitter<void> = new EventEmitter();

  onClick() {
    this.buttonClick.emit();
  }
}
