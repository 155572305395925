import {Component, OnInit} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {SharedService} from '../../../shared/shared.service';
import {Observable} from 'rxjs';
import {PrivacyEnCookieVerklaringUrl} from '../../../shared/models/privacy-verklaringen-model';
import {CookieService, CookieType} from '../../services/cookie.service';

@Component({
  selector: 'wv-cookie-settings-dialog',
  templateUrl: './cookie-settings-dialog.component.html',
  styleUrls: ['./cookie-settings-dialog.component.scss']
})
export class CookieSettingsDialogComponent implements OnInit {

  noodzakelijkeCookies = true;
  statistischeCookies = false;
  thirdPartyCookies = false;
  marketingCookies = false;
  privacyEnCookieverklaringUrl$: Observable<PrivacyEnCookieVerklaringUrl>;

  constructor(private dialogRef: MatDialogRef<CookieSettingsDialogComponent>,
              private cookieService: CookieService,
              private sharedService: SharedService) {
  }

  ngOnInit(): void {
    this.privacyEnCookieverklaringUrl$ = this.sharedService.getPrivacyVerklaringenUrls();

    if (this.cookieService.checkCookieExist(CookieType.STATISTIC)) {
      this.statistischeCookies = this.cookieService.isCookieAccepted(CookieType.STATISTIC);
    }

    if (this.cookieService.checkCookieExist(CookieType.THIRD_PARTY)) {
      this.thirdPartyCookies = this.cookieService.isCookieAccepted(CookieType.THIRD_PARTY);
    }

    if (this.cookieService.checkCookieExist(CookieType.MARKETING)) {
      this.marketingCookies = this.cookieService.isCookieAccepted(CookieType.MARKETING);
    }
  }

  acceptCookieChoice() {
    this.cookieService.setCookie(CookieType.NECESSARY, true);
    this.cookieService.setCookie(CookieType.STATISTIC, this.statistischeCookies);
    this.cookieService.setCookie(CookieType.THIRD_PARTY, this.thirdPartyCookies);
    this.cookieService.setCookie(CookieType.MARKETING, this.marketingCookies);
    this.dialogRef.close(true);
  }

  closeCookieSettings() {
    this.dialogRef.close(false);
  }

}
