import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'wv-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  @Input() afbeeldingUrls: Array<string>;

  constructor() {
  }

  ngOnInit(): void {
  }

}
