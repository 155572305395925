<div class="margin-bottom" [ngClass]="getClassListAsObject()">
  <wv-markdown
    *ngIf="dynamicContent?.isParagraph()">
    {{getDynamicContentParagraph(dynamicContent).content}}
  </wv-markdown>

  <wv-youtube-player
    *ngIf="dynamicContent?.isMedia()"
    [video]="getDynamicContentVideo(dynamicContent).media">
  </wv-youtube-player>
</div>

<div class="wv-padding">
  <wv-carousel
    *ngIf="dynamicContent?.isCarousel()"
    [afbeeldingUrls]="getDynamicContentCarousel(dynamicContent).afbeeldingUrls">
  </wv-carousel>
</div>

<wv-quote
  *ngIf="dynamicContent?.isQuote()"
  [quote]="getDynamicContentQuote(dynamicContent).quote">
</wv-quote>

<div *ngIf="dynamicContent?.isScrollPointer()" [id]="getDynamicContentScrollPointer(dynamicContent).contentId">
</div>
