import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wv-contact-and-nieuwsbrief',
  templateUrl: './contact-and-nieuwsbrief.component.html',
  styleUrls: ['./contact-and-nieuwsbrief.component.scss']
})
export class ContactAndNieuwsbriefComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
