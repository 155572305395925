<input [id]="label"
       type="checkbox"
       [(ngModel)]="value"
       [disabled]="disabled"
       (input)="onChange($event)"
       (blur)="onTouched()">
<label *ngIf="!link else withLink" [for]="label">{{label}}</label>
<ng-template #withLink>
  <label [for]="label"><a [href]="link" target="_blank">{{label}}</a></label>
</ng-template>
