<ng-container *ngIf="data$ | async as data">
  <wv-background-stripe [stripeOffsetY]="-5" [extraHeight]="14">
    <div class="projects wv-responsive-margin">
      <div class="left">
        <h3>{{data.andereProjectenTitel}}</h3>
        <wv-markdown [whiteText]="true">{{data.andereProjectenBeschrijving}}</wv-markdown>
      </div>
      <div class="right">
        <div *ngIf="!isTenant(Tenant.WARME_SCHOLEN)" class="own-project">
          <img title="Warme scholen logo" alt="warme scholen"
               src="./assets/images/Warme-Scholen.svg"/>
          <a href="https://www.warmescholen.net">Warme Scholen</a>
        </div>
        <div *ngIf="!isTenant(Tenant.WARM_VLAANDEREN)" class="own-project">
          <img title="Warm vlaanderen logo" alt="warm vlaanderen" src="./assets/images/Warm-Vlaanderen.svg"/>
          <a href="https://www.warmvlaanderen.net">Warm Vlaanderen</a>
        </div>
        <div *ngIf="!isTenant(Tenant.WARME_STEDEN_EN_GEMEENTEN)" class="own-project">
          <img title="Warme steden en gemeenten logo" alt="warme steden en gemeenten"
               src="./assets/images/Warm-Steden&Gemeenten.svg"/>
          <a href="https://www.warmestedenengemeenten.net">Warme Steden en Gemeenten</a>
        </div>
        <div class="own-project">
          <img title="Warme william logo" alt="warme william" src="./assets/images/Warme-William.svg"/>
          <a href="https://www.warmewilliam.be/">Warme William</a>
        </div>
      </div>
      <img src="assets/images/w-orginal.png" alt="W logo">
    </div>
  </wv-background-stripe>
  <div *ngIf="!isTenant(Tenant.WARM_VLAANDEREN)" class="partners">
    <h3 class="wv-responsive-margin">{{data.partnersTitel}}</h3>
    <wv-horizontal-scroll-list>
      <ng-container *ngFor="let circularImageLink of data.partners">
        <wv-circular-image-link [logo]="circularImageLink.logo?.data?.attributes" [url]="circularImageLink.url"></wv-circular-image-link>
      </ng-container>
    </wv-horizontal-scroll-list>
  </div>
  <div class="bottom-row row center-xs middle-xs" [ngClass]="{'blue-background': !isTenant(Tenant.WARM_VLAANDEREN)}">
    <div class="col-xs-4">
      <div class="row start-xs">
        <span>Gecoördineerd door het <a href="http://www.gavoorgeluk.be">Fonds GavoorGeluk</a>, son</span>
      </div>
    </div>

    <p class="col-xs-4">
      <ng-container *ngIf="!isTenant(Tenant.WARM_VLAANDEREN)">
        @ {{titel}}
      </ng-container>
    </p>

    <div class="col-xs-4">
      <div class="cookies-en-privacy">
        <ng-container *ngIf="privacyEnCookieVerklaring$ | async as verklaring">
          <a [href]="verklaring.privacyVerklaring">Privacy & voorwaarden</a>
          <a class="footer-manage-cookies" (click)="onCookieBeheerPressed()">Beheer Cookies</a>
          <a [href]="verklaring.cookieVerklaring">Cookies policy</a>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
