export class User {
  constructor(
    private authenticated: boolean,
    public id: string,
    public voornaam: string,
    public achternaam: string,
    public email: string,
    public organisatieId: string,
    public roles: Role[]
  ) {}

  static fromJwt(jwt: JwtPayload, dto?: UserDto): User {
    if (!!jwt) {
      return new User(
        true,
        jwt.sub,
        dto?.voornaam,
        dto?.achternaam,
        jwt.email,
        dto?.organisatieId,
        ((jwt['cognito:groups' as keyof JwtPayload] || []) as string[])
          .map((group) => Role[group.toUpperCase() as keyof typeof Role])
          .filter((role) => !!role)
      );
    } else {
      return User.anonymousUser();
    }
  }

  static anonymousUser(): User {
    return new User(false, '', '', '', '', '', []);
  }

  isAuthenticated(): boolean {
    return this.authenticated;
  }

  hasRole(role: Role): boolean {
    return this.roles.includes(role);
  }

  isAdmin(): boolean {
    return this.hasRole(Role.ADMIN);
  }

  isVliegwiel(): boolean {
    return this.hasRole(Role.VLIEGWIEL);
  }

  hasOneOfRoles(roles: Array<Role>): boolean {
    return !!roles.find((role) => this.hasRole(role));
  }
}

export enum Role {
  VLIEGWIEL = 'vliegwiel',
  ADMIN = 'admin',
}

export interface JwtPayload {
  sub: string;
  email: string;
}

export interface UserDto {
  id: string;
  voornaam: string;
  achternaam: string;
  email: string;
  organisatieId: string;
}
