import {Component, Input, OnInit} from '@angular/core';
import {HoeOndersteunen} from './hoe-ondersteunen-model';

@Component({
  selector: 'wv-hoe-ondersteunen',
  templateUrl: './hoe-ondersteunen.component.html',
  styleUrls: ['./hoe-ondersteunen.component.scss']
})
export class HoeOndersteunenComponent implements OnInit {

  @Input() hoeOndersteunenData: HoeOndersteunen;
  @Input() backgroundColorBear = 'white';

  constructor() {
  }

  ngOnInit(): void {
  }

  onButtonClick(url: string): void {
    window.open(url, '_self');
  }

}
