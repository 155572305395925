import {Directive, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {environment} from '../../../environments/environment';
import {isTenant, Tenant} from '../../website-configuration/tenant';

@Directive({ selector: '[wvHasGeslotenDeel]'})
export class HasGeslotenDeelDirective implements OnInit{
  private hasView = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef) { }

  ngOnInit(): void {
    if (this.hasGeslotenDeel() && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }

  private hasGeslotenDeel(): boolean {
    return !isTenant(Tenant.WARM_VLAANDEREN) && !environment.preview;
  }
}
