import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'wv-background-stripe',
  templateUrl: './background-stripe.component.html',
  styleUrls: ['./background-stripe.component.scss']
})
export class BackgroundStripeComponent implements OnInit {

  @Input() stripeOffsetY = 0;
  @Input() color = 'blue';
  @Input() zIndex = -1;
  @Input() extraHeight = 0;

  constructor() {
  }

  ngOnInit() {
    this.whenCachedReloadPage();
  }

  // Safari handles back/forward button cache differently and loads stripes css not correctly
  private whenCachedReloadPage() {
    window.onpageshow = (event)  => {
      if (event.persisted) {
        window.location.reload();
      }
    };
  }

}
