
export class NieuwsbriefInschrijving {

  constructor(public email: string,
              public nieuwsbrieven: Nieuwsbrief[]) {
  }
}

export enum Nieuwsbrief {
  WARME_SCHOLEN = 'WARME_SCHOLEN',
  WARME_WILLIAM = 'WARME_WILLIAM',
  WARME_STEDEN_EN_GEMEENTEN = 'WARME_STEDEN_EN_GEMEENTEN',
  WARM_VLAANDEREN = 'WARM_VLAANDEREN'
}
