import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CookieService as NgxCookieService} from 'ngx-cookie-service';
import {CookieDialogComponent} from './components/cookie-dialog/cookie-dialog.component';
import {CookieSettingsDialogComponent} from './components/cookie-settings-dialog/cookie-settings-dialog.component';
import {HttpClientModule} from '@angular/common/http';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';

@NgModule({
  declarations: [
    CookieDialogComponent,
    CookieSettingsDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    HttpClientModule,
    MatDialogModule,
    MatSlideToggleModule,
    FormsModule
  ],
  providers: [
    NgxCookieService,
  ]
})
export class CookieModule {
}
