import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {ContactModel, ContactModelDto} from '../models/contact-model';
import {CmsObject} from '../../../models/cms-components';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

  public getData(): Observable<ContactModel> {
    return this.http.get<CmsObject<ContactModelDto>>(`${environment.cmsApiUrl}/${environment.cmsApiVoorvoegsel}-contact`)
      .pipe(map(contactModelDto => ContactModel.fromDto(contactModelDto.data.attributes)));
  }
}
