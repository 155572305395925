import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {NavigationComponent} from './navigation/navigation.component';
import {RouterModule} from '@angular/router';
import {MatSidenavModule} from '@angular/material/sidenav';
import {SharedModule} from '../shared/shared.module';
import {HeaderMobileComponent} from './header/mobile/header-mobile.component';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {HeaderDesktopComponent} from './header/desktop/header-desktop.component';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';

@NgModule({
    declarations: [
        HeaderComponent,
        HeaderDesktopComponent,
        HeaderMobileComponent,
        FooterComponent,
        NavigationComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatButtonModule,
        MatMenuModule,
    ], exports: [
        HeaderComponent,
        FooterComponent
    ]
})
export class LayoutModule {
}
