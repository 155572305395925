import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {PrivacyEnCookieVerklaringUrl, PrivacyVerklaringUrlsDto} from './models/privacy-verklaringen-model';
import {CmsObject} from './models/cms-components';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private http: HttpClient) { }

  getPrivacyVerklaringenUrls(): Observable<PrivacyEnCookieVerklaringUrl> {
    return this.http.get<CmsObject<PrivacyVerklaringUrlsDto>>(
      `${environment.cmsApiUrl}/${environment.cmsApiVoorvoegsel}-privacy-en-cookie-verklaring-url`)
      .pipe(map(dto => PrivacyEnCookieVerklaringUrl.fromDto(dto?.data?.attributes)));
  }
}
