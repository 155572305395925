import {
  AfterViewInit,
  Component,
  ElementRef, HostListener,
  OnInit,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'wv-horizontal-scroll-list',
  templateUrl: './horizontal-scroll-list.component.html',
  styleUrls: ['./horizontal-scroll-list.component.scss']
})
export class HorizontalScrollListComponent implements OnInit, AfterViewInit {

  @ViewChild('lijst', {static: false}) lijst: ElementRef;

  canScroll = false;

  constructor() { }

  @HostListener('window:resize', ['$event']) onResize() {
    this.canScroll = this.lijst.nativeElement.clientWidth !== this.lijst.nativeElement.scrollWidth;
  };

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout(_ => this.canScroll = this.lijst.nativeElement.clientWidth !== this.lijst.nativeElement.scrollWidth);
  }

  scrollPrevious() {
    const cardWidth = this.lijst.nativeElement.lastElementChild.clientWidth;
    const distanceToMove = -cardWidth;
    this.animateScroll(distanceToMove, this.lijst.nativeElement);
  }

  scrollNext() {
    const distanceToMove = this.lijst.nativeElement.lastElementChild.offsetWidth;
    this.animateScroll(distanceToMove, this.lijst.nativeElement);
  }

  private animateScroll(distanceToMove: number, rail: HTMLDivElement) {
    const scrollStep = 3;
    distanceToMove -= distanceToMove % scrollStep;

    const interval = setInterval(() => {
      if (distanceToMove > 0) {
        rail.scrollLeft += scrollStep;
        distanceToMove -= scrollStep;
      }
      if (distanceToMove < 0) {
        rail.scrollLeft -= scrollStep;
        distanceToMove += scrollStep;
      }
      if (distanceToMove === 0) {
        clearInterval(interval);
      }
    }, 1);
  }

}
