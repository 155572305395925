import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'wv-tags-filter',
  templateUrl: './tags-filter.component.html',
  styleUrls: ['./tags-filter.component.scss']
})
export class TagsFilterComponent implements OnInit {

  @Input() keys: string[];
  @Input() labels: string[];
  @Input() initialSelectedKeys: string[] = [];
  @Output() selectedTypesChanged = new EventEmitter<string[]>();

  selected = false;
  options: { key: string; label: string; selected: boolean }[];

  ngOnInit(): void {
    this.options = this.keys
      .map((type, index) => (
        {
          key: type,
          label: this.labels[index],
          selected: this.initialSelectedKeys.includes(type)
        }
      ));
  }

  onToggleSelected(option: { key: string; label: string; selected: boolean }): void {
    option.selected = !option.selected;
    this.selectedTypesChanged.emit(this.getSelectedTags());
  }

  private getSelectedTags(): string[] {
    return this.options
      .filter(option => option.selected)
      .map(selectedOption => selectedOption.key);
  }
}
