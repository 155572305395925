import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {VoettekstModel, VoettekstModelDto} from './voettekst-model';
import {map} from 'rxjs/operators';
import {CmsObject} from '../../shared/models/cms-components';

@Injectable({
  providedIn: 'root'
})
export class FooterService {

  constructor(private http: HttpClient) { }

  public getData(): Observable<VoettekstModel> {
    return this.http.get<CmsObject<VoettekstModelDto>>(`${environment.cmsApiUrl}/${environment.cmsApiVoorvoegsel}-voettekst?populate=*`)
      .pipe(map(result => VoettekstModel.fromDto(result.data.attributes)));
  }
}
