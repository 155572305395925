import {Component, Input, OnInit} from '@angular/core';
import {Image} from '../../models/cms-components';

@Component({
  selector: 'wv-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {

  @Input() image: Image;
  @Input() defaultImage: string;
  @Input() defaultSize = true;

  constructor() { }

  ngOnInit(): void {
  }
}
