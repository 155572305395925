import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Tag} from '../../../gesloten-platform-dashboard-actieplan/models/tag';

@Component({
  selector: 'wv-filter-tag',
  templateUrl: './filter-tag.component.html',
  styleUrls: ['./filter-tag.component.scss']
})
export class FilterTagComponent {

  @Input() tag: Tag;
  @Input() selected = false;
  @Output() toggleTag: EventEmitter<Tag> = new EventEmitter<Tag>();

  onToggleSelected(tag: Tag) {
    this.toggleTag.emit(tag);
  }
}
