import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgxPrintService, PrintOptions} from 'ngx-print';

@Component({
  selector: 'wv-print-button',
  templateUrl: './print-button.component.html',
  styleUrls: ['./print-button.component.scss']
})
export class PrintButtonComponent {

  @Input() disabled = false;
  @Input() printSectionId: string;
  @Input() iconName;
  @Input() onlyIcon = false;


  constructor(private printService: NgxPrintService) {
  }

  print() {
    const customPrintOptions: PrintOptions = new PrintOptions({
      printSectionId: this.printSectionId,
      printTitle: 'Inspiratiebox Matrix',
      useExistingCss: true,
      openNewTab: true
    });
    this.printService.printStyle = {button: {display: 'none'}};
    this.printService.print(customPrintOptions);
  }
}
