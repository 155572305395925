<ng-container *ngIf="data$ | async as contactData">
  <h3>{{contactData.titel}}</h3>
  <p *ngIf="contactData.telefoon"><fa-icon icon="phone" class="icon"></fa-icon><a href="tel:{{contactData.telefoon}}">{{contactData.telefoon}}</a></p>
  <p *ngIf="contactData.email"><fa-icon icon="envelope" class="icon"></fa-icon><a href="mailto:{{contactData.email}}">{{contactData.email}}</a></p>

  <ng-container *ngFor="let contact of contactData.contacten">
    <div class="contact-container">
      <div class="thumbnail-box">
        <img *ngIf="contact.afbeelding?.formats?.thumbnail?.url else noImage" class="thumbnail" src="{{contact.afbeelding.formats.thumbnail.url}}" alt="">
        <ng-template #noImage>
          <img class="thumbnail" src="assets/images/thumbnail-placeholder.png" alt="">
        </ng-template>
      </div>
      <div class="info">
        <div>
          <p class="name">{{contact.naam}}</p>
          <p>{{contact.rol}}</p>
        </div>
        <div>
          <p *ngIf="contact.telefoon"><fa-icon icon="phone" class="icon"></fa-icon><a href="tel:{{contact.telefoon}}">{{contact.telefoon}}</a></p>
          <p *ngIf="contact.email"><fa-icon icon="envelope" class="icon"></fa-icon><a href="mailto:{{contact.email}}">{{contact.email}}</a></p>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
