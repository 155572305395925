<div class="dialog">
  <fa-icon size="1x" icon="exclamation-triangle"></fa-icon>
  <mat-dialog-content>
    <p>{{message}}</p>
  </mat-dialog-content>

  <mat-dialog-actions>
    <wv-button class="cancel-btn" (buttonClick)="cancel()">Sluiten</wv-button>
    <wv-button (buttonClick)="confirm()">Bevestigen</wv-button>
  </mat-dialog-actions>
</div>
