import {Component, Input, OnInit} from '@angular/core';
import {Quote} from '../../models/cms-components';

@Component({
  selector: 'wv-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss']
})
export class QuoteComponent implements OnInit {

  @Input() quote: Quote;

  constructor() { }

  ngOnInit(): void {
  }

}
