import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ContactService} from '../../services/contact.service';
import {ContactModel} from '../../models/contact-model';

@Component({
  selector: 'wv-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  data$: Observable<ContactModel>;

  constructor(private contactService: ContactService) { }

  ngOnInit(): void {
    this.data$ = this.contactService.getData();
  }

}
