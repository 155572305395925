import {CmsObject, Image} from '../../models/cms-components';

export class CircularImageLinkModel {
  constructor(
    public logo: CmsObject<Image>,
    public url: string) {
  }

  static fromDto(dto: CircularImageLinkModelDto): CircularImageLinkModel {
    return new CircularImageLinkModel(dto.logo, dto.url);
  }
}

export interface CircularImageLinkModelDto {
  logo: CmsObject<Image>;
  url: string;
}
