<ng-container *ngIf="privacyEnCookieVerklaringUrl$ | async as verklaring">
  <div class="dialog">
    <mat-dialog-content>
      <img src="./assets/images/cookie.png" alt="cookie-image">
      <p>We gebruiken cookies om je gebruikservaring te optimaliseren. Naast noodzakelijke cookies gebruiken we ook
        statistische, marketing en derde partij cookies. We vragen je toestemming voor het gebruik van deze cookies.
        <a href="{{verklaring.cookieVerklaring}}">Lees meer in de cookieverklaring.</a>
      </p>

    </mat-dialog-content>

    <mat-dialog-actions>
      <wv-button (click)="acceptAllCookies()">Alle cookies accepteren</wv-button>
      <a (click)="openCookieSettings()">Cookie-instellingen</a>
    </mat-dialog-actions>
  </div>
</ng-container>
