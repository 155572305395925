import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {isTenant, Tenant} from '../../website-configuration/tenant';

const MEGABYTE = 1000000;

export class WvValidators {

  static atLeastOne(formGroup: UntypedFormGroup): ValidationErrors | null {
    const isValid = Object.keys(formGroup.controls).some(key => {
      const control = formGroup.controls[key];
      return control.value;
    });
    return isValid ? null : {atLeastOne: true};
  }

  static atLeastOneOf(...controlNames: string[]): ValidatorFn {
    return (formGroup: UntypedFormGroup): ValidationErrors | null => {
      const isValid = controlNames.map(controlName => formGroup.get(controlName)).some(control => control.value);
      return isValid ? null : {atLeastOneOf: true};
    };
  }

  static email(control: UntypedFormControl): ValidationErrors | null {
    const regex = new RegExp('[^@ \\t\\r\\n]+@[^@ \\t\\r\\n]+\\.[^@ \\t\\r\\n]+');
    const isValid = regex.test(control.value);
    return isValid ? null : {email: true};
  }

  static nodebbUsername(control: UntypedFormControl): ValidationErrors | null {
    // https://github.com/NodeBB/NodeBB/blob/master/public/src/utils.js#L359
    const regex = new RegExp(/^['" \-+.*[\]0-9\u00BF-\u1FFF\u2C00-\uD7FF\w]+$/);
    const isValid = regex.test(control.value);
    return isValid ? null : {nodebbUsername: true};
  }

  static maxFileSize(sizeInMB: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const file: File = control.value;
      if (!file) {
        return null;
      }
      const isValid = file.size <= sizeInMB * MEGABYTE;
      return isValid ? null : {maxFileSize: true};
    };
  }

  static requiredForTenant(tenant: Tenant): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => isTenant(tenant) ? Validators.required(control) : null;
  }
}
