import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {map} from 'rxjs/operators';
import {Role} from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const roles = route.data.auth?.requiresOneOfRoles || [] as Array<Role>;
    if (this.authService.isAuthenticated()) {
      return this.authService.getLoggedInUser$()
        .pipe(map(user => user.hasOneOfRoles(roles) ? true : this.router.parseUrl('/platform/community')));
    }
    return of(false);
  }

}
