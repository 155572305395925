import {Directive, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {isTenant, Tenant} from '../../website-configuration/tenant';

@Directive({
  selector: '[wvIsWarmeScholen]'
})
export class IsWarmeScholenDirective implements OnInit {

  private visible = false;

  constructor(private viewContainerRef: ViewContainerRef,
              private templateRef: TemplateRef<any>) {
  }

  ngOnInit() {
    if (isTenant(Tenant.WARME_SCHOLEN)) {
      this.visible = true;
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.visible = false;
      this.viewContainerRef.clear();
    }
  }
}
