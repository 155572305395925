<div class="wv-responsive-margin">
  <div class="left-side">
    <div class="wv-title">{{hoeOndersteunenData.titel}}</div>
    <p>{{hoeOndersteunenData.content}}</p>
  </div>
  <div class="right-side" [ngClass]="backgroundColorBear">
    <button class="wv-bold-button"
            (click)="onButtonClick(hoeOndersteunenData.knop?.url)">
      {{hoeOndersteunenData.knop?.tekst}}
    </button>
    <img src="../../../../assets/images/beertje-no-legs.png" alt="beertje">
  </div>
</div>
