<ng-container *ngIf="image else checkDefaultImage">
  <div class="wrapper-large-player" [ngClass]="{'default': defaultSize}">
    <img [src]="image.url" [alt]="image.url">
  </div>
</ng-container>
<ng-template #checkDefaultImage>
  <ng-container *ngIf="defaultImage else noImage">
    <div class="wrapper-large-player" [ngClass]="{'default': defaultSize}">
      <img [src]="defaultImage" [alt]="'default img'">
    </div>
    </ng-container>
</ng-template>
<ng-template #noImage>
  <div class="no-image"></div>
</ng-template>

