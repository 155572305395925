import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UserService} from './user.service';
import {AuthConfig, OAuthEvent, OAuthService} from 'angular-oauth2-oidc';
import {from, Observable, ReplaySubject} from 'rxjs';
import {JwtPayload, User} from '../models/user';
import {getOAuthConfig} from '../auth.config';
import {Router} from '@angular/router';
import {filter, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private authConfiguration: AuthConfig;
  private readonly user$: ReplaySubject<User> = new ReplaySubject<User>(1);

  constructor(private http: HttpClient,
              private userService: UserService,
              private oauthService: OAuthService,
              private router: Router) {
    this.init();
  }

  init(): void {
    this.authConfiguration = getOAuthConfig();
    this.oauthService.configure(this.authConfiguration);
    from(this.oauthService.loadDiscoveryDocumentAndTryLogin()).subscribe();
    this.oauthService.events.subscribe(event => this.oauthEventCallback(event));
    if (this.oauthService.hasValidAccessToken()) {
      this.fetchAuthenticatedUser().subscribe();
    }
  }

  private oauthEventCallback(event: OAuthEvent): void {
    switch (event.type as string) {
      case 'token_received':
        this.fetchAuthenticatedUser()
          .subscribe(() => this.navigateToPage());
        break;
      case 'token_refresh_error':
        this.logout();
        break;
      case 'token_refreshed':
      case 'silently_refreshed':
      case 'session_changed':
        break;
    }
  }

  getLoggedInUser$(): Observable<User> {
    return this.user$.asObservable()
      .pipe(filter(user => !!user));
  }

  isAuthenticated(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  login(currentPath?: string): void {
    this.oauthService.initCodeFlow(currentPath);
  }

  logout(): void {
    this.user$.next(null);
    this.oauthService.logOut({
      client_id: this.authConfiguration.clientId,
      logout_uri: document.location.origin
    });
  }

  private fetchAuthenticatedUser(): Observable<User> {
    const tokenPayload = this.oauthService.getIdentityClaims();
    return this.userService.getMe$(tokenPayload as JwtPayload)
      .pipe(tap(user => this.user$.next(user)));
  }

  private navigateToPage() {
    const path = this.oauthService.state.replace(/%2F/gi, '/') ?? '/platform';
    this.router.navigate([path]);
  }
}
