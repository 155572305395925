import {AfterViewInit, ChangeDetectorRef, Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[wvScrollTo]',
})
export class ScrollToDirective implements AfterViewInit {

  private active = true;

  constructor(private elementRef: ElementRef,
              private changeDetector: ChangeDetectorRef) {
  }

  @Input()
  set wvScrollTo(active: boolean) {
    this.active = active;
  }

  ngAfterViewInit(): void {
    if (this.active) {
      const headerOffset = 75;
      const elementPosition = this.elementRef.nativeElement.getBoundingClientRect().top;
      const offsetPosition = elementPosition - headerOffset;
      window.scrollTo({top: offsetPosition});
      this.changeDetector.detectChanges();
    }
  }
}
