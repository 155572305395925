import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';
import {AuthService} from '../../../auth/services/auth.service';
import {Observable} from 'rxjs';
import {User} from '../../../auth/models/user';
import {NotificationType} from '../../../shared/notification/notification.component';
import {MediaMatcher} from '@angular/cdk/layout';
import {NotificationService} from '../../../shared/notification/notification.service';


@Component({
  selector: 'wv-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss']
})
export class HeaderMobileComponent implements OnInit, OnDestroy {

  @ViewChild('sidenav') sidenav: MatSidenav;
  logo = environment.websiteConfiguratie.logo;
  menuItems = environment.websiteConfiguratie.menu;

  mobileLoginQuery: MediaQueryList;
  private readonly mobileLoginQueryListener: () => void;

  user$: Observable<User>;

  constructor(
    private router: Router,
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private notificationService: NotificationService
  ) {
    this.mobileLoginQuery = media.matchMedia('(max-width: 768px)');
    this.mobileLoginQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileLoginQuery.addEventListener('change', this.mobileLoginQueryListener);
  }

  ngOnInit(): void {
    this.user$ = this.authService.getLoggedInUser$();
  }

  goToLogin() {
    const isSmartphone = this.mobileLoginQuery.matches;
    if (isSmartphone) {
      this.showScreensizeToSmallErrorMessage();
    } else {
      this.authService.login('/platform');
    }
  }

  logout() {
    this.authService.logout();
    this.close();
  }

  goToGeslotenPlatform(): void {
    this.router.navigate(['/platform']);
    this.close();
  }

  toggle() {
    if (!this.sidenav.opened) {
      this.sidenav.open();
      disableBodyScroll(this.sidenav);
    } else {
      this.close();
    }
  }

  close() {
    this.sidenav.close();
    enableBodyScroll(this.sidenav);
  }

  ngOnDestroy() {
    clearAllBodyScrollLocks();
  }

  isUserAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  private showScreensizeToSmallErrorMessage() {
    this.notificationService.showNotificationBar(
      'Deze schermgrootte is niet geschikt om in te loggen. Probeer op een Pc/Tablet',
      NotificationType.WARNING);
  }
}
