import {Page, SortOrder} from './page';

export class PageRequest<T> {

  static firstPageRequest<G>(sortOrder: SortOrder, pageSize: number): PageRequest<G> {
    return new PageRequest<G>(
      sortOrder === SortOrder.ASCENDING,
      pageSize,
      1,
      0,
    );
  }

  static nextPageRequest<G>(currentPage: Page<G>): PageRequest<G> {
    return new PageRequest<G>(
      currentPage.isAscending(),
      currentPage.getPageSize(),
      currentPage.getNextPageNumber(),
      currentPage.getPageNumber(),
      currentPage.getFirstItemOnPage(),
      currentPage.getLastItemOnPage(),
    );
  }

  static previousPageRequest<G>(currentPage: Page<G>): PageRequest<G> {
    return new PageRequest<G>(
      currentPage.isAscending(),
      currentPage.getPageSize(),
      currentPage.getPreviousPageNumber(),
      currentPage.getPageNumber(),
      currentPage.getFirstItemOnPage(),
      currentPage.getLastItemOnPage(),
    );
  }

  constructor(public ascending: boolean,
              public pageSize: number,
              public pageNumber: number,
              public previousPageNumber: number,
              public firstItemPreviousPage?: T,
              public lastItemPreviousPage?: T,
  ) {
  }

  getSortOrder(): SortOrder {
    return this.ascending ? SortOrder.ASCENDING : SortOrder.DESCENDING;
  }

  getPageSize(): number {
    return this.pageSize;
  }

  toFirstPage(): PageRequest<T> {
    return PageRequest.firstPageRequest<T>(this.getSortOrder(), this.getPageSize());
  }
}
