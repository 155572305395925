import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DraftInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (environment.preview && this.isCmsApi(request.url)) {
      return next.handle(this.getRequestWithPreviewParam(request));
    }
    return next.handle(request);
  }

  private isCmsApi(url: string): boolean {
    return url.startsWith(environment.cmsApiUrl);
  }

  private getRequestWithPreviewParam(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({params: request.params.set('publicationState', 'preview')});
  }
}
