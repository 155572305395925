import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './routing/app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LayoutModule} from './layout/layout.module';
import {SecurityModule} from './security/security.module';
import {CookieModule} from './cookie/cookie.module';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptor} from './auth/auth.interceptor';

import {SharedModule} from './shared/shared.module';
import {OAuthModule, OAuthStorage} from 'angular-oauth2-oidc';
import {MatomoConsentMode, NgxMatomoTrackerModule} from '@ngx-matomo/tracker';
import {NgxMatomoRouterModule} from '@ngx-matomo/router';
import {environment} from '../environments/environment';

const APP_MODULES = [
  LayoutModule,
  SecurityModule,
  CookieModule,
  SharedModule
];

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    ...APP_MODULES,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    OAuthModule.forRoot(),
    NgxMatomoTrackerModule.forRoot({
      siteId: environment.matomoSiteId,
      trackerUrl: 'https://warmvlaanderen.matomo.cloud/',
      disabled: environment.preview || environment.matomoSiteId === '',
      requireConsent: MatomoConsentMode.TRACKING,
    }),
    NgxMatomoRouterModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    {provide: OAuthStorage, useValue: sessionStorage},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
  ]
})
export class AppModule {
}
