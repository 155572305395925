<div class="dialog">
  <fa-icon icon="exclamation"></fa-icon>
  <mat-dialog-content>
    <strong>Nog 1 stap: Check je e-mail en bevestig je registratie</strong>
    <p>Je ontvangt zo dadelijk een e-mail met een tijdelijk wachtwoord.</p>
    <p>Stel <span class="underline">onmiddellijk</span> je persoonlijk wachtwoord in.</p>
    <p>Vergeet niet in je spam- of junkfolder te kijken.</p>
  </mat-dialog-content>

  <mat-dialog-actions>
    <wv-button (buttonClick)="close()">Ik begrijp het!</wv-button>
  </mat-dialog-actions>
</div>
