<ng-container [formGroup]="form">
  <h3>{{data.titel}}</h3>
  <wv-markdown>
    {{data.introTekst}}
  </wv-markdown>

  <div class="checkboxes" [formGroup]="nieuwsbrieven">
    <wv-checkbox [ngClass]="{'first-position': isTenant(Tenant.WARME_STEDEN_EN_GEMEENTEN)}"
      label="Warme Steden & Gemeenten"
      formControlName="warmeStedenEnGemeenten">
    </wv-checkbox>
    <wv-checkbox [ngClass]="{'first-position': isTenant(Tenant.WARME_SCHOLEN)}"
      label="Warme Scholen"
      formControlName="warmeScholen">
    </wv-checkbox>
    <wv-checkbox
      label="Warme William"
      formControlName="warmeWilliam">
    </wv-checkbox>
    <wv-checkbox [ngClass]="{'first-position': isTenant(Tenant.WARM_VLAANDEREN)}"
      label="Warm Vlaanderen"
      formControlName="warmVlaanderen">
    </wv-checkbox>
    <div class="wv-error" *ngIf="nieuwsbrieven.hasError('atLeastOne') && nieuwsbrieven.touched">
      Er moet minstens 1 nieuwsbrief geselecteerd worden
    </div>
  </div>

  <wv-text-input
    placeholder="E-mail"
    formControlName="email">
  </wv-text-input>
  <div class="wv-error" *ngIf="email.hasError('required') && email.touched">
    E-mail is verplicht
  </div>
  <div class="wv-error" *ngIf="email.hasError('email') && email.touched">
    E-mail is niet geldig
  </div>

  <p>Door je in te schrijven voor de nieuwsbrief ga je akkoord met de <span><a href="{{data.voorwaardenUrl}}">gebruiksvoorwaarden</a></span></p>

  <wv-button (buttonClick)="onInschrijven()">Inschrijven</wv-button>
</ng-container>
