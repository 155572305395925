import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Page} from '../page';
import {PageRequest} from '../page-request';

@Component({
  selector: 'wv-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent {

  @Input()
  currentPage: Page<any>;
  @Output()
  pageChange: EventEmitter<PageRequest<any>> = new EventEmitter<PageRequest<any>>();

  onPreviousClick(): void {
    this.pageChange.emit(PageRequest.previousPageRequest(this.currentPage));
  }

  onNextClick(): void {
    this.pageChange.emit(PageRequest.nextPageRequest(this.currentPage));
  }
}
