import {AuthGuard} from '../auth/guards/auth.guard';
import {Role} from '../auth/models/user';

export const inspiratieRoute = {
  path: 'inspiratie',
  loadChildren: () => import('../inspiratie/inspiratie.module').then(m => m.InspiratieModule)
};

export const bouwstenenRoute = {
  path: 'bouwstenen',
  loadChildren: () => import('../bouwstenen/bouwstenen.module').then(m => m.BouwstenenModule)
};

export const zinactiviteitenRoute = {
  path: 'zinactiviteiten',
  loadChildren: () => import('../zinactiviteiten/zinactiviteiten.module').then(m => m.ZinactiviteitenModule)
};

export const materiaalRoute = {
  path: 'materiaal',
  loadChildren: () => import('../materiaal/materiaal.module').then(m => m.MateriaalModule)
};

export const nieuwsRoute = {
  path: 'nieuws',
  loadChildren: () => import('../nieuws/nieuws.module').then(m => m.NieuwsModule)
};

export const paginasRoute = {
  path: 'paginas',
  loadChildren: () => import('../generieke-pagina/generieke-pagina.module').then(m => m.GeneriekePaginaModule)
};

export const homeRoute = {
  path: 'home',
  loadChildren: () => import('../home/home.module').then(m => m.HomeModule)
};

export const netwerkRoute = {
  path: 'netwerk',
  loadChildren: () => import('../netwerk/netwerk.module').then(m => m.NetwerkModule)
};

export const pijlersRoute = {
  path: 'pijlers',
  loadChildren: () => import('../bouwstenen/bouwstenen.module').then(m => m.BouwstenenModule)
};

export const inspiratieboxRoute = {
  path: 'inspiratiebox',
  loadChildren: () => import('../inspiratiebox/inspiratiebox.module').then(m => m.InspiratieboxModule)
};

export const authRoute = {
  path: 'auth',
  loadChildren: () => import('../auth/auth.module').then(m => m.AuthModule)
};

export const platformRoute = {
  path: 'platform',
  canActivate: [AuthGuard],
  loadChildren: () => import('../gesloten-platform/platform.module').then(m => m.PlatformModule),
  data: {auth: {requiresOneOfRoles: [Role.ADMIN, Role.VLIEGWIEL]}},
};

export const zinmeterRoute = {
  path: 'zinmeter/:organisatieId',
  loadChildren: () => import('../zinanalyse/zinmeter/zinmeter.module').then(m => m.ZinmeterModule)
};
