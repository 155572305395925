import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NieuwsbriefInschrijving} from '../models/nieuwsbrief-inschrijving';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {NieuwsbriefModel} from '../models/nieuwsbrief-model';
import {CmsObject} from '../../../models/cms-components';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NieuwsbriefService {


  constructor(private http: HttpClient) {
  }

  inschrijven(inschrijving: NieuwsbriefInschrijving): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/public/nieuwsbrief`, inschrijving);
  }

  getData(): Observable<NieuwsbriefModel> {
    return this.http.get<CmsObject<NieuwsbriefModel>>(`${environment.cmsApiUrl}/nieuwsbrief`)
      .pipe(map(nieuwsbrief => nieuwsbrief?.data?.attributes));
  }
}
