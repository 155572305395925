import { Injectable } from '@angular/core';
import {MatomoTracker} from '@ngx-matomo/tracker';

@Injectable({
  providedIn: 'root'
})
export class MatomoAnalyticsService {
  constructor(private matomoTracker: MatomoTracker) {}

  initializeTracking(): void {
    this.matomoTracker.setConsentGiven();
  }

  removeTracking(): void {
    this.matomoTracker.forgetConsentGiven();
  }
}
