import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {environment} from '../../environments/environment';
import {
  authRoute,
  bouwstenenRoute,
  platformRoute,
  homeRoute,
  inspiratieRoute,
  materiaalRoute,
  netwerkRoute,
  nieuwsRoute,
  paginasRoute,
  pijlersRoute,
  zinactiviteitenRoute,
  zinmeterRoute,
  inspiratieboxRoute
} from './routes';
import {Tenant} from '../website-configuration/tenant';

const wsRoutes: Routes = [
  inspiratieRoute,
  bouwstenenRoute,
  zinactiviteitenRoute,
  materiaalRoute,
  nieuwsRoute,
  paginasRoute,
  authRoute,
  platformRoute,
  zinmeterRoute
];

const wsgRoutes: Routes = [
  inspiratieRoute,
  pijlersRoute,
  inspiratieboxRoute,
  zinactiviteitenRoute,
  materiaalRoute,
  nieuwsRoute,
  paginasRoute,
  authRoute,
  platformRoute,
  zinmeterRoute
];

const wvRoutes: Routes = [
  homeRoute,
  nieuwsRoute,
  paginasRoute,
  netwerkRoute
];

let routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: `${environment.websiteConfiguratie.initialPage}`
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: `${environment.websiteConfiguratie.initialPage}`
  }
];

switch (environment.tenant) {
  case Tenant.WARM_VLAANDEREN:
    routes = [...wvRoutes, ...routes];
    break;
  case Tenant.WARME_SCHOLEN:
    routes = [...wsRoutes, ...routes];
    break;
  case Tenant.WARME_STEDEN_EN_GEMEENTEN:
    routes = [...wsgRoutes, ...routes];
}

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload',
    scrollOffset: [0, 110]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
