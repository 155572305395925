export class Page<T> {

  constructor(
    private ascending: boolean,
    private pageSize: number,
    private pageNumber: number,
    private lastPage: boolean,
    private items: Array<T>,
  ) {
  }

  isAscending(): boolean {
    return this.ascending;
  }

  getSortOrder(): SortOrder {
    return this.isAscending() ? SortOrder.ASCENDING : SortOrder.DESCENDING;
  }

  getPreviousPageNumber(): number {
    return this.isFirstPage() ? this.getPageNumber() : this.getPageNumber() - 1;
  }

  getNextPageNumber(): number {
    return this.isLastPage() ? this.getPageNumber() : this.getPageNumber() + 1;
  }

  getPageNumber(): number {
    return this.pageNumber;
  }

  isFirstPage(): boolean {
    return this.getPageNumber() === 1;
  }

  isLastPage(): boolean {
    return this.lastPage;
  }

  getPageSize(): number {
    return this.pageSize;
  }

  getFirstItemOnPage(): T {
    return this.items[0];
  }

  getLastItemOnPage(): T {
    return this.items[this.items.length - 1];
  }

  getItems(): T[] {
    return this.items;
  }

  isEmpty(): boolean {
    return this.getNumberOfItemsOnPage() === 0;
  }

  private getNumberOfItemsOnPage(): number {
    return this.getItems().length;
  }
}

export interface PageDto<T> {
  ascending: boolean;
  pageSize: number;
  pageNumber: number;
  lastPage: boolean;
  items: Array<T>;
}

export enum SortOrder {
  ASCENDING,
  DESCENDING,
}

