<ng-container *ngIf="video">
  <div [ngClass]="playerSize === PlayerSizeType.LARGE ? 'wrapper-large-player' : 'wrapper-small-player'">
    <ng-container *ngIf="videoUrl">
      <ng-container *ngIf="thirdPartyCookieSet">
        <img *ngIf="!isVisible" src="https://i.ytimg.com/vi/{{videoId}}/maxresdefault.jpg" alt="Video">
        <iframe [hidden]="!isVisible" #player [src]="videoUrl | safe" frameborder="0"
                allow="autoplay; encrypted-media"
                allowfullscreen>
        </iframe>
        <button *ngIf="!isVisible"
                [ngClass]="playerSize === PlayerSizeType.LARGE ? 'play-btn-large' : 'play-btn-small'"
                (click)="play()">
          <img src="assets/icons/play-button.svg" alt="play-button">
        </button>
      </ng-container>
      <div *ngIf="!thirdPartyCookieSet" class="cookie-not-accepted">
        <div>
          <fa-icon icon="exclamation-triangle"></fa-icon>
          Let op! Je kan momenteel geen video's bekijken omdat je hiervoor geen cookies hebt geaccepteerd. Wil je toch
          video's bekijken? Wijzig dan je <a (click)="openCookieSettings()">cookie instellingen</a> en accepteer de
          derde
          partij cookies.
        </div>
      </div>
    </ng-container>
    <wv-image *ngIf="!videoUrl" [image]="foto" [defaultSize]="false"></wv-image>
    <ng-container *ngIf="!thirdPartyCookieSet">

    </ng-container>
  </div>
</ng-container>
