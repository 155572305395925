import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {
  DynamicContent,
  DynamicContentCarousel,
  DynamicContentMedia,
  DynamicContentParagraph,
  DynamicContentQuote, DynamicContentScrollPointer
} from './dynamic-content';

@Component({
  selector: 'wv-dynamic-content',
  templateUrl: './dynamic-content.component.html',
  styleUrls: ['./dynamic-content.component.scss']
})
export class DynamicContentComponent implements OnInit, AfterViewInit {

  @Input() dynamicContent: DynamicContent;
  @Input() cssClasses: string[] = [];
  @Input() scrollPointId: string;

  constructor() { }

  ngOnInit(): void {
  }

  getDynamicContentParagraph(content: DynamicContent): DynamicContentParagraph {
    return content as DynamicContentParagraph;
  }

  getDynamicContentVideo(content: DynamicContent): DynamicContentMedia {
    return content as DynamicContentMedia;
  }

  getDynamicContentCarousel(content: DynamicContent) {
    return content as DynamicContentCarousel;
  }

  getDynamicContentQuote(content: DynamicContent) {
    return content as DynamicContentQuote;
  }

  getDynamicContentScrollPointer(content: DynamicContent) {
    return content as DynamicContentScrollPointer;
  }

  getClassListAsObject() {
    return this.cssClasses.map(className => ({[`${className}`]: true}))
      .reduce((prev, current) => ({...prev, ...current}), {});
  }

  ngAfterViewInit(): void {
    this.scrollTofragment();
  }

  private scrollTofragment() {
      if (this.scrollPointId) {
        const element = document.getElementById(this.scrollPointId);
        console.log('element: ' + element);
        if (element) {
          element.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
      }
  }
}
