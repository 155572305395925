import {Component, Input} from '@angular/core';

@Component({
  selector: 'wv-tag-card-view',
  templateUrl: './tag-card-view.component.html',
  styleUrls: ['./tag-card-view.component.scss']
})
export class TagCardViewComponent {

  @Input() labels: string[];

}
