import {WebsiteConfiguratie} from './website-configuratie';

export const WarmeScholenConfig: WebsiteConfiguratie = {
  initialPage: 'inspiratie',
  logo: {
    logoImg: 'Warme-Scholen.svg',
    logoTitle: 'warme scholen',
    logoAlt: 'Warme scholen logo',
  },
  menu: [
    {
      label: 'Inspiratie',
      websiteRoute: 'inspiratie',
      metaTagDescription: 'Dit is de beschrijving van de inspiratie pagina voor warme scholen'
    },
    {
      label: 'Bouwstenen',
      websiteRoute: 'bouwstenen',
      metaTagDescription: 'Dit is de beschrijving van de bouwstenen pagina voor warme scholen'
    },
    {
      label: 'Zinactiviteiten',
      websiteRoute: 'zinactiviteiten',
      metaTagDescription: 'Dit is de beschrijving van de zinactiviteiten pagina voor warme scholen'
    },
    {
      label: 'Materiaal',
      websiteRoute: 'materiaal',
      metaTagDescription: 'Dit is de beschrijving van de materiaal pagina voor warme scholen'
    },
    {
      label: 'Nieuws',
      websiteRoute: 'nieuws',
      metaTagDescription: 'Dit is de beschrijving van de nieuws pagina voor warme scholen'
    }
  ],
  organisatieType: {
    label: 'school',
    labelMeervoud: 'Scholen',
    warmeTitel: 'Warme School',
    warmeTitelMeervoud: 'Warme Scholen',
    beschrijving: 'school of scholengroep'
  },
  zinmeter: {
    rollen: [
      {
        id: 'leerkracht',
        label: 'Leerkrachten'
      },
      {
        id: 'directie-bestuur',
        label: 'Directie en Bestuur'
      },
      {
        id: 'administratief-technisch',
        label: 'Administratie'
      },
      {
        id: 'externe-partner',
        label: 'Externe partners'
      }
    ],
    uitleg: `<div>
    De zinmeter gaat na of er vuur zit in de school om een Warme School te worden. Het draagvlak wordt gemeten.
    Je kan de link van de bevraging kopiëren en doorsturen naar alle belanghebbenden van de school
    (leerkrachten, directie/bestuur, externe partners). Heb je een actieve ouderraad, dan kan je hen ook betrekken
    bij de zinmeter en opnemen onder de categorie externe partners, net als bv.
    CLB of andere organisaties die betrokken zijn bij de school. Je kan zelf bepalen in hoeverre dit tot de cultuur
    van jouw school behoort om ook deze externe partners
    mee te nemen in de bevraging. De resultaten zullen getoond worden op deze pagina van zodra de zinmeter door
    meerdere personen wordt ingevuld.<br><br>

    De zinmeter informeert de school over het beeld dat men heeft van een Warme School de mate waarin men de
    expeditie wilt, de mate waarin de expeditie
    moet en de mate waarin de expeditie kan. De zinmeting heeft pas zin als de deelnemers goed geïnformeerd zijn over
    de inhoud van de warme school.<br><br>

    De informatie kan men verkrijgen door de <a href="/inspiratie">inspiratiepagina</a> te raadplegen of door een
    <a href="/zinactiviteiten">zinreis of zinspiratiesessie</a> te volgen.
    </div>`,
    woordenwolkHeaders: [
      {
        id: 'open-vraag-droombeeld',
        titel: 'Droom Warme School',
        beschrijving: 'Wat zien, voelen en beleven de belanghebbenden als hun Warme School werkelijkheid is.'
      },
      {
        id: 'open-vraag-wat-nodig',
        titel: 'Nodig voor Warme School',
        beschrijving: 'Wat vindt iedereen dat er nodig is voor een Warme School.'
      }
    ]
  },
  zinscan: {
    naam: 'Zinscan',
    urlNaam: 'zinscan',
    uitleg: `<div>
    Met de zinscan kan je de evolutie van jouw expeditie meten en opvolgen doorheen de tijd. We raden je aan om de
    zinscan 2 keer per jaar in te vullen. De zinscan bevat 6 kenmerken met telkens 10 stellingen. De kenmerken van de
    Warme Scholen realiseer je door aan de verschillende <a href="/bouwstenen">bouwstenen</a> te werken. <br><br>
    Vul deze zinscan in samen met het werkgroep eventueel ondersteund met jouw zincoach.
    Je kan ook andere belanghebbenden betrekken bij het invullen van de vragenlijst.
    Van zodra je de zinscan invult, zie je op deze pagina jouw evolutie aan de hand van een spindiagram. Je krijgt ook
    feedback over welke stapstenen je helpen om dichter bij een Warme School te komen.
    </div>`,
    beschrijvingStartZinscan: `Start of vervolledig hier de zinscan. De vragen zijn gegroepeerd in zes kenmerken.
    Je kan het invullen van de zinscan onderbreken nadat je een kenmerk hebt afgewerkt.`,
    beschrijvingDownloadIngevuldeZinscan: 'Download hier de resultaten van jouw zinscan en ontdek de evolutie in jullie proces',
    beschrijvingDownloadLegeZinscan: `Wil je op voorhand de antwoorden verzamelen van het vliegwiel of andere belanghebbenden?
      Download de vragen hier, stuur de vragenlijst dan door en bundel de antwoorden. Vul daarna het resultaat in op de zinscan`,
    spinnenwebInfoText: `Deze resultaten tonen de voortgang van jouw expeditie aan de hand van een spinnenweb.
                         Per kenmerk zie je de voortgang op de 10 stellingen.
                         De resultaten van de eerste en de laatste 3 ingevulde zinscans zullen worden getoond. Deze kan je ook afvinken.
                         Onderaan vind je per kenmerk feedback.`,
  },
  dashboard: {
    adviesgesprekMail: 'tom.vanacker@warmescholen.net',
    organisatie: 'Warme Scholen',
    adviesgesprekText: `Bespreek je graag de resultaten met een medewerker van Warme Scholen? Dat kan! Plan hier gratis
     één adviesgesprek op maat.`,
    adviesgesprekButtonText: 'Adviesgesprek',
    startZinmeterBeschrijving: `Wil je als vliegwiel het draagvlak meten? Zorg voor zoveel mogelijk antwoorden. Kopieer
    hier de link en stuur door naar alle belanghebbenden.`
  },
  beheer: {
    gebruikersLijstTitel: 'Vliegwiel',
    gebruikersLijstBeschrijving: `Beheer hier de leden van het <a href="/bouwstenen/sleutels-tot-succes/stapstenen/vliegwiel">vliegwiel</a>
  die toegang hebben tot het platform van jullie school. Het vliegwiel bestaat uit een groep
  enthousiaste medewerkers die de weg naar een Warme School mee willen en kunnen faciliteren. Zij
  modereren de dialoog tussen belanghebbenden om de droom van een Warme School te ontwikkelen. Ze
  brengen voorstellen naar voor en stimuleren initiatieven om de mensen hiervoor in beweging te brengen.
  <br><br>
  Als je een nieuw lid aan het vliegwiel toevoegt, krijgt deze persoon een mail om in te loggen en kan hij/zij daarna
  ook gebruik maken van jullie dashboard en de community.`
  },
  tools: {
    beschrijving: `<p>
    Hier kan je heel wat instrumenten uitwisselen die bijdragen tot een Warme School.<br><br>
    Misschien heb je een instrument om het samenwerken
    in teams gemakkelijker te maken: een planningsinstrument, een vergadersjabloon, een teambord, een instrument om ieders talent te
    leren kennen, …<br>
    Of heb je een instrument waarmee de leerlingen de eigen regie kunnen opnemen of waarmee je leerlingen beter
    kunt volgen en begeleiden in
    hun persoonlijk traject: een evaluatieinstrument, een manier om betrokkenheid en welbevinden te monitoren met gepaste interventies, …
    Heb je een goede tool om ouders te informeren of te betrekken, …? <br><br>
    Alles wat je hier deelt is open source en mag door iedereen gebruikt worden. Zo help je anderen op weg naar een nog Warmere School.
    Je kan ook korte, opbouwende feedback geven op de instrumenten.
    </p>`
  },
  referenties: {
    titel: 'Inspirerende scholen',
    // eslint-disable-next-line max-len
    beschrijving: `<p>
    Hier vind je een lijst van inspirerende scholen die elk op hun manier vergevorderd zijn in het proces van een Warme School.
    Wil je zelf ook andere scholen inspireren? Laat het ons weten via <a href="mailto:info@warmescholen.net">info@warmescholen.net</a>.
    </p>`
  }
};
