<ng-container *ngIf="currentPage">
  <button
    class="wv-bold-button"
    (click)="onPreviousClick()"
    [class.invisible]="currentPage.isFirstPage()">
    <fa-icon icon="chevron-left"></fa-icon>
  </button>

  <div class="items-on-page mat-small">
    pagina {{currentPage.getPageNumber()}}
  </div>

  <button
    class="wv-bold-button"
    (click)="onNextClick()"
    [class.invisible]="currentPage.isLastPage()">
    <fa-icon icon="chevron-right"></fa-icon>
  </button>

</ng-container>
