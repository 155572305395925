import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {VoettekstModel} from './voettekst-model';
import {FooterService} from './footer.service';
import {environment} from '../../../environments/environment';
import {SharedService} from '../../shared/shared.service';
import {PrivacyEnCookieVerklaringUrl} from '../../shared/models/privacy-verklaringen-model';
import {isTenant, Tenant} from '../../website-configuration/tenant';
import {CookieDialogService} from '../../cookie/services/cookie-dialog.service';

@Component({
  selector: 'wv-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  data$: Observable<VoettekstModel>;
  privacyEnCookieVerklaring$: Observable<PrivacyEnCookieVerklaringUrl>;
  titel: string;

  isTenant = isTenant;
  Tenant = Tenant;

  constructor(private footerService: FooterService,
              private sharedService: SharedService,
              private cookieDialogService: CookieDialogService) {
  }

  ngOnInit(): void {
    this.data$ = this.footerService.getData();
    this.privacyEnCookieVerklaring$ = this.sharedService.getPrivacyVerklaringenUrls();
    this.titel = environment.applicationTitle;
  }

  onCookieBeheerPressed(): void {
    this.cookieDialogService.showCookieDialog();
  }
}
