<ng-container *ngIf="privacyEnCookieverklaringUrl$ | async as verklaring">
  <div class="dialog">
    <a>
      <fa-icon icon="times" (click)="closeCookieSettings()"></fa-icon>
    </a>

    <h2>Cookie-settings</h2>
    <mat-dialog-content>
      <p class="tool-beschrijving">Cookies zijn "minibestanden" en kunnen worden geplaatst op uw apparaat dat is verbonden
        met
        het internet, zoals een computer, telefoon, tablet of smart TV. Cookies kunnen worden gebruikt om informatie te
        verzamelen of op te slaan over hoe u zich gedraagt op een website en/of op uw apparaat. De "lezing" van deze
        cookies stelt de websites die ze hebben geplaatst in staat om de informatie die erin is opgeslagen op te vragen.
        Lees onze <a href="{{verklaring.cookieVerklaring}}">cookie-</a> en <a href="{{verklaring.privacyVerklaring}}">privacyverklaring-</a>
        bij het bezoeken van onze website.
      </p>

      <div class="settings-option">
        <div class="header">
          <h3 class="title">Noodzakelijke cookies</h3>
          <div class="checkbox">
            <label [for]="'baseCookie'">Altijd actief</label>
            <input [id]="'baseCookie'" type="checkbox"
                   [(ngModel)]="noodzakelijkeCookies"
                   [disabled]="true">
          </div>
        </div>
        <p>
          Deze cookies zijn nodig om puur technische redenen voor een normaal bezoek aan de website. Gezien de
          technische noodzaak geldt alleen een informatieverplichting, en deze cookies worden geplaatst zodra je de
          website bezoekt.
        </p>

      </div>

      <div class="settings-option">
        <div class="header">
          <h3 class="title">Statistische cookies</h3>
          <div class="checkbox">
            <label [for]="'baseCookie'">Optioneel</label>
            <input [id]="'baseCookie'" type="checkbox"
                   [(ngModel)]="statistischeCookies"
                   [disabled]="false">
          </div>
        </div>
        <p>Deze cookies gebruiken we onder meer om te weten hoeveel keer een bepaalde pagina gelezen wordt. Deze
          informatie gebruiken we enkel om de inhoud van onze website te verbeteren. Zij brengen, volledig anoniem, jouw
          gebruik in kaart voor analyse en onderzoek.
        </p>
      </div>

      <div class="settings-option">
        <div class="header">
          <h3 class="title">Derde partij cookies</h3>
          <div class="checkbox">
            <label [for]="'baseCookie'">Optioneel</label>
            <input [id]="'baseCookie'" type="checkbox"
                   [(ngModel)]="thirdPartyCookies"
                   [disabled]="false">
          </div>
        </div>
        <p>Deze website bevat links naar YouTube video's. ‘Embedded content’ van platforms als YouTube bevatten vaak
          cookies. Wat deze externe partijen met die cookies doen, is ons niet bekend. De gegevens zijn voor ons niet
          toegankelijk en we hebben ook geen invloed op het al dan niet plaatsen van deze cookies. Als je ze weigert,
          wordt de content van YouTube niet getoond.
        </p>

      </div>

      <div class="settings-option">
        <div class="header">
          <h3 class="title">Marketing cookies</h3>
          <div class="checkbox">
            <label [for]="'baseCookie'">Optioneel</label>
            <input [id]="'baseCookie'" type="checkbox"
                   [(ngModel)]="marketingCookies"
                   [disabled]="false">
          </div>
        </div>
        <p>
          We gebruiken cookies om content en advertenties te personaliseren.
          Hiervoor delen we informatie over je gebruik van de website met onze partners voor social media (Facebook, LinkedIn).
        </p>

      </div>

    </mat-dialog-content>

    <mat-dialog-actions>
      <wv-button (click)="acceptCookieChoice()">Mijn keuze bevestigen</wv-button>
    </mat-dialog-actions>
  </div>

</ng-container>
