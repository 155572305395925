<div class="notification"
     [class.information]="isInformation(notificationType)"
     [class.warning]="isWarning(notificationType)">
  <div class="content">
    <ng-container *ngIf="isInformation(notificationType)">
      <fa-icon icon="info"></fa-icon><span>{{message}}</span>
    </ng-container>
    <ng-container *ngIf="isWarning(notificationType)">
      <fa-icon icon="exclamation-triangle"></fa-icon><span>{{message}}</span>
    </ng-container>
  </div>
</div>
