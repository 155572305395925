<button
  [class.cancel-button]="isCancelButton"
  [disabled]="disabled"
  (click)="onClick()">
  <ng-content>
  </ng-content>
  <fa-icon
    *ngIf="iconName"
    [icon]="iconName" [class.onlyIcon]="onlyIcon">
  </fa-icon>
</button>
