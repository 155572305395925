import {Component, OnInit} from '@angular/core';
import {NieuwsbriefInschrijving} from '../../models/nieuwsbrief-inschrijving';
import {NieuwsbriefService} from '../../services/nieuwsbrief.service';
import {Observable} from 'rxjs';
import {NieuwsbriefModel} from '../../models/nieuwsbrief-model';

@Component({
  selector: 'wv-nieuwsbrief',
  templateUrl: './nieuwsbrief.component.html',
  styleUrls: ['./nieuwsbrief.component.scss']
})
export class NieuwsbriefComponent implements OnInit {

  data$: Observable<NieuwsbriefModel>;

  inschrijvingVerstuurd = false;
  foutOpgetreden = false;
  inschrijving: NieuwsbriefInschrijving;

  constructor(private nieuwsbriefService: NieuwsbriefService) {
  }

  ngOnInit(): void {
    this.data$ = this.nieuwsbriefService.getData();
  }

  onInschrijving(inschrijving: NieuwsbriefInschrijving) {
    this.inschrijving = inschrijving;
    this.nieuwsbriefService.inschrijven(inschrijving)
      .subscribe(
        () => this.inschrijvingVerstuurd = true,
        () => this.foutOpgetreden = true
      );
  }
}
