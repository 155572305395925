<div *ngIf="logo" class="card-padding flex">
  <ng-container *ngIf="url else noUrl">
    <a [attr.href]="url">
      <div class="circle"><img [src]="logo?.formats?.thumbnail?.url" alt="logo"/></div>
    </a>
  </ng-container>
  <ng-template #noUrl>
    <div class="circle"><img [src]="logo?.formats?.thumbnail?.url" alt="logo"/></div>
  </ng-template>
</div>
