import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthService} from '../../auth/services/auth.service';
import {Role} from '../../auth/models/user';

@Directive({
  selector: '[wvRequiresOneOfRoles]'
})
export class RequiresOneOfRolesDirective {

  private visible = false;

  constructor(private viewContainerRef: ViewContainerRef,
              private templateRef: TemplateRef<any>,
              private authService: AuthService) {
  }

  @Input()
  set wvRequiresOneOfRoles(roles: Array<Role>) {
    this.authService.getLoggedInUser$().subscribe(user => {
      if (!user.hasOneOfRoles(roles)) {
        this.visible = false;
        this.viewContainerRef.clear();
      } else if (!this.visible) {
        this.visible = true;
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      }
    });
  }
}
