import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {PlayerSize} from './player-size';
import {CmsObject, Image, Media} from '../../models/cms-components';
import {CookieDialogService} from '../../../cookie/services/cookie-dialog.service';

@Component({
  selector: 'wv-youtube-player',
  templateUrl: './youtube-player.component.html',
  styleUrls: ['./youtube-player.component.scss']
})
export class YoutubePlayerComponent implements OnInit {

  @ViewChild('player', {static: false}) player: ElementRef;

  @Input() video: Media;
  @Input() playerSize: PlayerSize = PlayerSize.LARGE;

  PlayerSizeType = PlayerSize;
  isVisible: boolean;
  videoId: string;
  videoUrl: string;
  thirdPartyCookieSet: boolean;

  constructor(private cookieDialogService: CookieDialogService) { }

  ngOnInit(): void {
    this.thirdPartyCookieSet = this.cookieDialogService.isThirdPartyCookieAccepted();
    if (this.video?.videoUrl) {
      this.extractVideoIdAndUrl();
    }
  }

  get foto(): Image {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (<CmsObject<Image>>this.video.foto)?.data ? (<CmsObject<Image>>this.video.foto).data.attributes : <Image>this.video.foto;
  }

  play() {
    this.isVisible = true;
    this.player.nativeElement.src += '&autoplay=1';
  }

  openCookieSettings() {
    this.cookieDialogService.showCookieDialog().subscribe();
  }

  private extractVideoIdAndUrl(): void {
    this.videoId = this.video.videoUrl.split('v=')[1];
    const ampersandPosition = this.video.videoUrl.indexOf('&');
    if (ampersandPosition !== -1) {
      this.videoId = this.video.videoUrl.substring(0, ampersandPosition);
    }
    this.videoUrl = 'https://www.youtube.com/embed/' + this.videoId + '?rel=0';
  }
}
