import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {WvValidators} from '../../../../form/validators';
import {Nieuwsbrief, NieuwsbriefInschrijving} from '../../models/nieuwsbrief-inschrijving';
import {NieuwsbriefModel} from '../../models/nieuwsbrief-model';
import {isTenant, Tenant} from '../../../../../website-configuration/tenant';

@Component({
  selector: 'wv-nieuwsbrief-form',
  templateUrl: './nieuwsbrief-form.component.html',
  styleUrls: ['./nieuwsbrief-form.component.scss']
})
export class NieuwsbriefFormComponent implements OnInit {

  @Input() data: NieuwsbriefModel;
  @Output() inschrijving: EventEmitter<NieuwsbriefInschrijving> = new EventEmitter<NieuwsbriefInschrijving>();

  form: UntypedFormGroup;

  isTenant = isTenant;
  Tenant = Tenant;

  constructor() {
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      nieuwsbrieven: new UntypedFormGroup({
        warmeScholen: new UntypedFormControl(false),
        warmeWilliam: new UntypedFormControl(false),
        warmeStedenEnGemeenten: new UntypedFormControl(false),
        warmVlaanderen: new UntypedFormControl(false)
      }, WvValidators.atLeastOne),
      email: new UntypedFormControl('', [Validators.required, Validators.email])
    });
  }

  onInschrijven(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.inschrijving.emit(new NieuwsbriefInschrijving(
        this.email.value,
        this.getSelectedControlNames().map(control => this.mapNieuwsbrieven(control))
      ));
    }
  }

  get nieuwsbrieven(): UntypedFormGroup {
    return this.form.get('nieuwsbrieven') as UntypedFormGroup;
  }

  get warmeScholen(): UntypedFormControl {
    return this.nieuwsbrieven.get('warmeScholen') as UntypedFormControl;
  }

  get warmeWilliam(): UntypedFormControl {
    return this.nieuwsbrieven.get('warmeWilliam') as UntypedFormControl;
  }

  get warmeStedenEnGemeenten(): UntypedFormControl {
    return this.nieuwsbrieven.get('warmeStedenEnGemeenten') as UntypedFormControl;
  }

  get warmVlaanderen(): UntypedFormControl {
    return this.nieuwsbrieven.get('warmVlaanderen') as UntypedFormControl;
  }

  get email(): UntypedFormControl {
    return this.form.get('email') as UntypedFormControl;
  }

  private getSelectedControlNames(): string[] {
    return Object.keys(this.nieuwsbrieven.controls)
      .filter(control => this.nieuwsbrieven.controls[control].value === true);
  }

  private mapNieuwsbrieven(controlName: string): Nieuwsbrief {
    const mapper = {
      warmeScholen: Nieuwsbrief.WARME_SCHOLEN,
      warmeWilliam: Nieuwsbrief.WARME_WILLIAM,
      warmeStedenEnGemeenten: Nieuwsbrief.WARME_STEDEN_EN_GEMEENTEN,
      warmVlaanderen: Nieuwsbrief.WARM_VLAANDEREN
    };
    return mapper[controlName];
  }
}
