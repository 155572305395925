import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'wv-gradient-button-component',
  templateUrl: './gradient-button.component.html',
  styleUrls: ['./gradient-button.component.scss']
})
export class GradientButtonComponent implements OnInit {

  @Output() buttonClick: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClick() {
    this.buttonClick.emit();
  }
}
