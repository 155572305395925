import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA} from '@angular/material/legacy-snack-bar';

@Component({
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  message: string;
  notificationType: NotificationType = NotificationType.INFORMATION;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }

  ngOnInit(): void {
    this.message = this.data.message;
    this.notificationType = this.data.notificationType;
  }

  isWarning(notificationType: NotificationType): boolean {
    return notificationType === NotificationType.WARNING;
  }

  isInformation(notificationType: NotificationType): boolean {
    return notificationType === NotificationType.INFORMATION;
  }

}

export enum NotificationType {
  INFORMATION,
  WARNING
}
