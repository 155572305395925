<mat-carousel
  timings="250ms ease-in"
  [autoplay]="true"
  [interval]=5000
  color="primary"
  maxWidth="768px"
  [proportion]="50"
  [loop]="true"
  [hideArrows]="false"
  [hideIndicators]="false"
  [useKeyboard]="true"
  [useMouseWheel]="false"
  orientation="ltr"
>
  <mat-carousel-slide
    #matCarouselSlide
    *ngFor="let slide of afbeeldingUrls; let i = index"
    [image]="slide"
    [hideOverlay]="true"
  ></mat-carousel-slide>
</mat-carousel>
