import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {OAuthStorage} from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authStorage: OAuthStorage) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isPrivateApi(request.url)) {
      return next.handle(this.getRequestWithToken(request));
    } else {
      return next.handle(request);
    }
  }

  private isPrivateApi(url: string): boolean {
    return url.startsWith(`${environment.apiUrl}`) && !url.includes('/public/');
  }

  private getRequestWithToken(request: HttpRequest<any>): HttpRequest<any> {
    const token = this.authStorage.getItem('id_token');
    return request.clone({setHeaders: {Authorization: `Bearer ${token}`}});
  }
}
