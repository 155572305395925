import {Component, Input, OnInit} from '@angular/core';
import {Image} from '../../models/cms-components';

@Component({
  selector: 'wv-circular-image-link',
  templateUrl: './circular-image-link.component.html',
  styleUrls: ['./circular-image-link.component.scss']
})
export class CircularImageLinkComponent implements OnInit {

  @Input() logo: Image;
  @Input() url: string;

  constructor() { }

  ngOnInit(): void {
  }

}
